/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { IoDocument, IoPerson } from 'react-icons/io5';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import { DocumentStatusDetails, DocumentStatusValues } from '../constants';

import Button from '~/components/Button';
import Page from '~/components/Page';
import { formatCpfOrCnpj } from '~/utils';
import api from '~/services/api';

function EnrollmentsForm({ match, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [enrollment, setEnrollment] = useState(false);

  const { userId, contractId } = match.params;

  const { user, documents, contract } = enrollment || {};

  const formattedDocument = formatCpfOrCnpj(user?.document);

  const hasDocumentsToAnalysis = documents?.find(
    (doc) => doc.status === DocumentStatusValues.UNDER_REVIEW,
  );

  const handleBack = useCallback(() => {
    history.push('/documents/users');
  }, [history]);

  const handleDownloadReceipt = useCallback(async () => {
    try {
      const response = await api.post(
        '/college/download-receipt',
        {
          userId,
        },
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'receipt.pdf');

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(
        'Não foi possível fazer download do comprovante de pagamento.',
      );
      // console.error('Erro ao fazer download', error);
    }
  }, [userId]);

  useEffect(() => {
    const loadEnrollment = async () => {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(
          `/documents/users/${userId}/contracts/${contractId}`,
        );

        setEnrollment(response);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId && contractId) {
      loadEnrollment();
    }
  }, [contractId, userId]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>Documentos de matrícula</h1>

        <div>
          <Button color="cancel" onClick={handleBack}>
            Voltar
          </Button>
          {/* <Button>Salvar matrícula</Button> */}
        </div>
      </header>

      <div className="flex mt-8 py-3 items-center">
        <h2 className="flex-1 text-[#302B3E] text-xl font-bold">
          Dados do aluno
        </h2>
      </div>

      <div className="flex p-4 pr-5 justify-between items-center gap-4 rounded-[8px] border border-solid border-[#E5E7EB]">
        {/* Info */}
        <div className="flex items-center gap-3 flex-shrink-0">
          {user?.avatar_url ? (
            <img
              width={40}
              height={40}
              alt={user.name}
              src={user.avatar_url}
              className="rounded-md"
            />
          ) : (
            <div className="flex size-10 justify-center items-center rounded-sm bg-[#EEE]">
              <IoPerson className="size-6 text-[#989898]" />
            </div>
          )}

          <div className="flex flex-col justify-center gap-1">
            <span className="text-[#18181B] font-semibold">{user?.name}</span>
            <span className="text-black text-sm">{user?.email}</span>
          </div>
        </div>

        {/* CPF */}
        {formattedDocument && (
          <span className="text-black text-sm">{formattedDocument}</span>
        )}

        <div className="flex items-center gap-1">
          {contract?.company?.avatar_url && (
            <img
              src={contract?.company?.avatar_url}
              width={32}
              height={32}
              alt=""
            />
          )}

          <span className="text-[#18181B] font-semibold">
            {contract?.title}
          </span>
        </div>

        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/users/edit/${user?.id}`}
          className="text-[#7159C1] text-xs font-bold uppercase hover:underline"
        >
          Ver cadastro
        </Link>
      </div>

      <section className="flex flex-col justify-center gap-3">
        <div className="flex mt-8 py-3 items-center">
          <h2 className="flex-1 text-[#302B3E] text-xl font-bold">
            Lista de documentos
          </h2>

          {hasDocumentsToAnalysis && (
            <Button
              color="success"
              to={`/documents/users/${user?.id}/${contract?.id}/documents`}
            >
              Analisar documentos
            </Button>
          )}
        </div>

        {documents?.map((document) => {
          const {
            icon: Icon,
            color,
            label,
          } = DocumentStatusDetails[document?.status];

          const formattedSendDate = document?.sended_at
            ? ` Enviado em ${format(
                new Date(document?.sended_at),
                'DD/MM/YYYY HH:mm',
              )}`
            : null;

          return (
            <div
              key={document?.id}
              className="flex p-4 pr-5 justify-between items-center gap-4 rounded-[8px] border border-solid border-[#E5E7EB]"
            >
              {/* Info */}
              <div className="flex max-w-[380px] w-full items-center gap-3 flex-shrink-0">
                <div className="flex size-10 justify-center items-center rounded-sm bg-[#EEE]">
                  <IoDocument className="size-6 text-[#989898]" />
                </div>

                <div className="flex flex-col justify-center items-start gap-1">
                  <span className="text-[#18181B] text-base font-semibold">
                    {document?.name}
                  </span>

                  <span className="text-[#222222] text-sm">
                    {formattedSendDate}
                  </span>
                </div>
              </div>

              {/* Status */}
              <div className="flex max-w-[120px] w-full items-center gap-1 flex-shrink-0">
                <Icon color={color} />

                <span className="text-black text-sm">{label}</span>
              </div>

              {document?.path ? (
                <Link
                  to={`/documents/users/${user?.id}/${contract?.id}/documents/${document?.id}`}
                  className="text-[#7159C1] text-xs font-bold uppercase cursor-pointer hover:underline"
                >
                  Visualizar
                </Link>
              ) : (
                <span className="text-[#7159C1]/25 text-xs font-bold uppercase cursor-not-allowed">
                  Visualizar
                </span>
              )}
            </div>
          );
        })}

        <div className="flex p-4 pr-5 justify-between items-center gap-4 rounded-[8px] border border-solid border-[#E5E7EB]">
          {/* Info */}
          <div className="flex max-w-[380px] w-full items-center gap-3 flex-shrink-0">
            <div className="flex size-10 justify-center items-center rounded-sm bg-[#EEE]">
              <IoDocument className="size-6 text-[#989898]" />
            </div>

            <div className="flex flex-col justify-center items-start gap-1">
              <span className="text-[#18181B] text-base font-semibold">
                Comprovante de pagamento
              </span>
            </div>
          </div>

          {/* Status */}
          <div className="flex max-w-[120px] w-full items-center gap-1 flex-shrink-0">
            {/* <Icon color="#7159C1" /> */}

            <span className="text-black text-sm">Disponível para download</span>
          </div>

          <button type="button" onClick={() => handleDownloadReceipt()}>
            <span className="text-[#7159C1] text-xs font-bold uppercase">
              Download
            </span>
          </button>
        </div>
      </section>
    </Page>
  );
}

EnrollmentsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      contractId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default EnrollmentsForm;
