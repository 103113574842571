/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { MdAdd, MdDelete, MdRestore } from 'react-icons/md';
import debounce from 'debounce-promise';
import { ChevronDown, ChevronUp } from 'lucide-react';

import { Fieldset } from '../styles';
import { SectionContainer } from './styles';

import { Dropdown, Datepicker, Input, Check } from '~/components/HookForm';
import api from '~/services/api';
import Button from '~/components/Button';
import { DEFAULT_DEBOUNCE_VALUE } from '~/utils';
import ReleaseAtImage from '~/assets/images/tooltips/events/release-lesson-field.png';
import AvailableAtImage from '~/assets/images/tooltips/events/available-lesson-field.png';
import OfferModalImage from '~/assets/images/tooltips/events/offer-modal-field.png';
import { cn } from '~/lib/utils';

const LessonItem = React.memo(
  ({ item, trailIndex, lessonIndex, restoreLesson, removeLesson }) => {
    const { control, watch } = useFormContext();

    const hasOfferModal = watch(
      `eventTrails[${trailIndex}].trailLessons[${lessonIndex}].metadata.has_offer_modal`,
    );

    const [isMetadataOpen, setIsMetadataOpen] = useState(false);

    const isMarkedToDelete = item?._destroy;

    async function loadLessons(search) {
      const { data: response } = await api.get('/lessons', {
        params: {
          per_page: 50,
          search,
        },
      });

      const lessons = response.data.map((lesson) => ({
        value: lesson.id,
        label: `${lesson.title} (${lesson.type})`,
      }));

      return lessons;
    }

    const loadAsyncLessons = useCallback(
      debounce(loadLessons, DEFAULT_DEBOUNCE_VALUE),
      [],
    );

    return (
      <Fieldset>
        <div className="flex flex-col gap-2 relative">
          {isMarkedToDelete && (
            <Button
              icon={MdRestore}
              color="warning"
              onClick={restoreLesson}
              style={{ height: '50px' }}
              className="z-10 absolute top-[20%] left-[50%] -translate-x-[50%]"
            >
              Desfazer remoção
            </Button>
          )}

          <div
            className={isMarkedToDelete ? 'opacity-30 pointer-events-none' : ''}
          >
            <Input
              name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].lessonTrailId`}
              type="hidden"
            />

            <section>
              <Controller
                name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].lesson`}
                control={control}
                defaultValue={item.lesson}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    multiline
                    async
                    label="Aula"
                    load={loadAsyncLessons}
                    loadDefaultValue={async (value) => value}
                    placeholder="Selecione uma aula"
                    isClearable
                  />
                )}
              />

              <Datepicker
                label="Data da exibição"
                name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].available_at`}
                description={`Data e hora que será exibido que a aula será disponibilizada, porém essa informação é apenas para exibição.
                  <img src="${AvailableAtImage}" className="w-[500px]" />  
                `}
                placeholder="Data de exibição que a aula será liberada."
                showTimeSelect
                required
                timeIntervals={5}
                style={{ height: '50px' }}
              />

              <Datepicker
                label="Data de liberação"
                name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].release_at`}
                description={`Data e hora que a aula estará disponível, esse é o campo que de fato libera... ou seja, podemos liberar antes da data que fica exibido.
                  <img src="${ReleaseAtImage}" className="w-[500px]" />  
                `}
                placeholder="Data em que o aluno terá acesso a aula"
                showTimeSelect
                required
                timeIntervals={5}
                style={{ height: '50px' }}
              />

              <Check
                label="Aula é uma Live"
                name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].is_live`}
                required
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
              />

              <Button
                color="danger"
                icon={MdDelete}
                onClick={removeLesson}
                className="mt-[30px] h-[48px]"
              />
            </section>
          </div>

          <div className="flex flex-col">
            <button
              type="button"
              className="flex items-center justify-between w-fit gap-2 py-2 px-1"
              onClick={() => setIsMetadataOpen(!isMetadataOpen)}
            >
              <span>Metadata</span>

              {isMetadataOpen ? <ChevronUp /> : <ChevronDown />}
            </button>

            <div className={cn(isMetadataOpen ? 'flex flex-col' : 'hidden')}>
              <Fieldset>
                <legend>Metadata</legend>

                <section>
                  <Check
                    label="Exibir modal de oferta ao final da aula"
                    name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].metadata.has_offer_modal`}
                    options={[
                      { value: true, label: 'Sim' },
                      { value: false, label: 'Não' },
                    ]}
                  />

                  <Input
                    name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].metadata.offer_modal_title`}
                    label="Headline da oferta"
                    description={`Headline exibida no modal.
              <img src="${OfferModalImage}" className="w-[500px]" />
            `}
                    placeholder="Você ganhou um presente exclusivo."
                    disabled={!hasOfferModal}
                  />
                </section>

                <section>
                  <Input
                    name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].metadata.offer_modal_cta_text`}
                    label="Texto do botão CTA"
                    description={`Link do botão secundário pós inscrição.
              <img src="${OfferModalImage}" className="w-[500px]" />
            `}
                    placeholder="https://www.rocketseat.com.br/eventos/nlw/inscricao/"
                    disabled={!hasOfferModal}
                  />

                  <Input
                    name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].metadata.offer_modal_cta_link`}
                    label="URL Botão CTA"
                    description={`Link do Botão de CTA da oferta
              <img src="${OfferModalImage}" className="w-[500px]" />
            `}
                    placeholder="https://www.rocketseat.com.br/eventos/nlw/inscricao/"
                    disabled={!hasOfferModal}
                  />

                  <Input
                    name={`eventTrails[${trailIndex}].trailLessons[${lessonIndex}].metadata.offer_modal_video`}
                    label="Código do vídeo do Youtube"
                    description={`Código do vídoe do youtube que será exibido.
              <img src="${OfferModalImage}" className="w-[500px]" />

              <b>Exemplo:</b>
              <p style="display: flex; items-align:center;">
              https://www.youtube.com/watch?v=<span style="border: 1px solid red; padding: 1.5px 2px; display: inline-block; border-radius: 5px; background: #0a0a0a">
                UVlFt05vND4
              </p>
              </span>`}
                    placeholder="xlH6zmdGz-E"
                    disabled={!hasOfferModal}
                  />
                </section>
              </Fieldset>
            </div>
          </div>
        </div>
      </Fieldset>
    );
  },
);

LessonItem.propTypes = {
  item: PropTypes.object.isRequired,
  restoreLesson: PropTypes.func.isRequired,
  removeLesson: PropTypes.func.isRequired,
  trailIndex: PropTypes.number.isRequired,
  lessonIndex: PropTypes.number.isRequired,
  defaultTrailValues: PropTypes.object.isRequired,
  isParentMarkedToDelete: PropTypes.bool,
};

LessonItem.defaultProps = {
  isParentMarkedToDelete: false,
};

const lessonDefault = {
  lesson: null,
  available_at: null,
  metadata: {
    has_offer_modal: false,
  },
};

export const LessonSection = React.memo(
  ({ defaultTrailValues, trailIndex, isParentMarkedToDelete }) => {
    const { control, getValues } = useFormContext();

    const lessonsFields = useFieldArray({
      control,
      name: `eventTrails[${trailIndex}].trailLessons`,
    });

    const handleAdd = useCallback(() => {
      lessonsFields.append(lessonDefault);
    }, []);

    const handleRemove = useCallback((itemIndex) => {
      const isNew = false;

      if (isNew) {
        return lessonsFields.remove(itemIndex);
      }

      const trailLessonItem = getValues(
        `eventTrails[${trailIndex}].trailLessons[${itemIndex}]`,
      );

      lessonsFields.update(itemIndex, {
        ...trailLessonItem,
        _destroy: true,
      });
    }, []);

    const handleRestore = useCallback((itemIndex) => {
      const trailItem = getValues(
        `eventTrails[${trailIndex}].trailLessons[${itemIndex}]`,
      );

      delete trailItem._destroy;

      lessonsFields.update(itemIndex, trailItem);
    }, []);

    return (
      <SectionContainer>
        <div className="justify-end relative -top-[50px] -mb-[40px]">
          <Button icon={MdAdd} color="success" onClick={handleAdd}>
            NOVA AULA
          </Button>
        </div>

        {lessonsFields.fields.map((item, index) => (
          <LessonItem
            key={item.id}
            item={item}
            lessonIndex={index}
            trailIndex={trailIndex}
            defaultTrailValues={defaultTrailValues}
            isParentMarkedToDelete={isParentMarkedToDelete}
            removeLesson={() => handleRemove(index)}
            restoreLesson={() => handleRestore(index)}
          />
        ))}
      </SectionContainer>
    );
  },
);

LessonSection.propTypes = {
  defaultTrailValues: PropTypes.object.isRequired,
  trailIndex: PropTypes.number.isRequired,
  isParentMarkedToDelete: PropTypes.bool,
};

LessonSection.defaultProps = {
  isParentMarkedToDelete: false,
};
